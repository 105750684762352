/* 

Color Palette
https://www.canva.com/colors/color-palettes/blossom-of-porcelain/
Cyan: #00E0D4
White: #EAEFF2
Red Orange: #FF4500

*/

.white-space {
  white-space: pre-line;
}

ul {
  margin-left: 8%;
}

.cookie-container {
  position: fixed;
  bottom: 0;
  height: 95px;
  width: 100%;
  background-color: #ffffff;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  box-shadow: 0 1px #eaeff2 inset;
  z-index: 5;
}

.cookie-container p {
  font-size: 14px;
}

.cookies-learn-more {
  text-decoration: none;
  color: #999999;
  transition: 200ms;
}

@media (hover: hover) {
  .cookies-learn-more:hover {
    color: #000000;
  }
}

.cookie-btns-container {
  display: flex;
  gap: 15px;
}

.cookie-btns-container button {
  border: none;
  outline: inherit;
  height: fit-content;
  padding: 15px;
  font-size: 14px;
  transition: 200ms;
  cursor: pointer;
}

.cookie-accept-btn {
  background-color: #ff4500;
  color: #ffffff;
}

.mobile-header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  height: 65px;
  width: 100%;
  box-shadow: 0 -1px #eaeff2 inset;
  top: 0;
  background-color: #ffffff;
  z-index: 12;
}

.mobile-menu {
  background-color: #ffffff;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  top: 0%;
  right: -100%;
  position: fixed;
  z-index: 12;
  transition: 350ms;
}

.enabled {
  right: 0%;
}

.mobile-menu a {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.mobile-menu-close-btn {
  position: absolute;
  margin: 15px;
  top: 0;
  right: 0;
}

.lang-div-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 12px; */
  font-weight: 300;
  position: absolute;
  bottom: 0;
  padding: 35px;
}

.lang-options {
  display: flex;
  gap: 5px;
}

.lang-btn {
  padding: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.lang-div-mobile p {
  font-weight: 300;
}

.desktop-header {
  position: fixed;
  top: 0;
  min-width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  color: #101010;
  box-shadow: 0 -1px #eaeff2 inset;
  z-index: 12;
}

.header-container {
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-navs {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.desktop-header a,
.mobile-header a,
.mobile-menu a {
  text-decoration: none;
  color: #101010;
}

.header-actions a {
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  transition: 200ms;
}

.header-actions-active {
  /* color: #00e0d4 !important; */
  color: #ff4500 !important;
}

.desktop-lang-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  cursor: pointer;
  transition: 200ms;
}

.lang-btn-desktop {
  background: none;
  border: none;
  cursor: pointer;
}

.dropbtn {
  background-color: #ffffff;
  color: #101010;
  border: none;
  display: flex;
  gap: 8px;
  text-transform: uppercase;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-left: -85px;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 15;
  border-style: solid;
  border-width: 1px;
}

@media (hover: hover) {
  .desktop-lang-option:hover {
    background-color: rgb(236, 236, 236);
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .header-actions a:hover {
    /* color: #00e0d4; */
    color: #ff4500;
  }
}

.title {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  cursor: pointer;
}

.media {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  cursor: pointer;
}

.video-button {
  position: absolute;
  bottom: 5%;
  color: #ffffff;
  cursor: pointer;
}

.eco-home-image-container {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.eco-home-image {
  display: flex;
  height: 80px;
  width: 1000px;
  background-image: url(../assets/eco.jpg);
  background-size: contain;
  background-repeat: no-repeat;
}

.livro-de-reclamacoes-container {
  height: 80px;
  background-color: #eaeff2;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  box-shadow: 0 1px #eaeff2 inset;
}

.livro-de-reclamacoes-sub-container {
  width: 1000px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
  .livro-de-reclamacoes-sub-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.livro-de-reclamacoes-image {
  cursor: pointer;
  display: flex;
  height: 50px;
  width: 120px;
  background-image: url(../assets/livro-de-reclamacoes.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1100px) {
  .eco-home-image-container {
    padding: 10%;
    padding-top: 0%;
  }
}

.description,
.contact,
.services,
.service {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact,
.services,
.service {
  padding-top: 65px;
}

.contact h1 {
  line-height: 35px;
}

@media only screen and (max-width: 1100px) {
  .contact h1 {
    font-size: 26px;
  }
}

.description-container,
.contact-container,
.service-container {
  width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.services-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 1000px;
  min-height: 100vh;
}

.services-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  gap: 15px;
}

.services-cards-container a {
  all: unset;
}

.service-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #eaeff2;
}

.service-card {
  /* background-color: #1b1b1b; */
  height: 420px;
  width: 320px;
  transition: 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 30%); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.service-card-text {
  padding: 5px;
  transition: 200ms;
}

.scanning-image-container {
  height: 320px;
  transition: 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (hover: hover) {
  .service-card-container:hover {
    color: #ff4500;
  }

  .shop-card:hover {
    font-size: 18px;
  }
}

.service-card h3 {
  color: #ffffff;
}

.services-cards-container h2 {
  color: #ffffff;
}

.contact-container,
.services-container,
.service-container {
  padding-top: 36px;
}

@media only screen and (max-width: 1100px) {
  .contact-container,
  .services-container,
  .service-container {
    padding-top: 50px;
  }
}

.services-bottom-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.service-container {
  gap: 15px;
  min-height: 100vh;
}

.service-back {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  width: fit-content;
  transition: 200ms;
}

@media (hover: hover) {
  .service-back:hover {
    color: #ff4500;
  }
}

.service-description {
  white-space: pre-line;
  margin-top: 15px;
}

.service-contact-link {
  text-decoration: none;
  width: fit-content;
}

.service-button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  outline: inherit;
  height: fit-content;
  padding: 15px;
  font-size: 14px;
  transition: 200ms;
  cursor: pointer;
  color: #101010;
}

.big-format-info {
  color: #999999;
  font-size: 14px;
}

.shop-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 1100px) {
  .shop-title h1 {
    font-size: 26px;
  }
}

.shop-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shop-form select,
.shop-form input {
  padding: 5px;
  outline: none;
  border: none;
  border-radius: 0px;
  background-color: #f5f5f5;
}

@media only screen and (min-width: 1100px) {
  .shop-cat-form-container {
    margin-top: 95px;
    width: 325px;
  }
}

.shop-attachment-img {
  font-size: 12px;
  color: #999999;
}

.shop-attachment {
  width: 100%;
  cursor: pointer;
}

.shop-text-area {
  resize: none;
}

.shop-promo-code {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.shop-promo-code input {
  width: 100%;
}

.shop-promo-code div {
  background-color: #ff4500;
  color: #ffffff;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}

.shop-required-fields {
  font-size: 12px;
  color: #999999;
  align-self: flex-end;
  text-align: end;
}

.shop-button {
  border: none;
  outline: inherit;
  height: fit-content;
  padding: 15px;
  font-size: 14px;
  transition: 200ms;
  cursor: pointer;
  color: #ffffff;
  background-color: #ff4500;
}

.shop-warning {
  font-size: 14px;
}

.shop-cat-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.shop-cat-left-side {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.shop-tshirts-printing {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
}

.shop-flyers-finish {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
}

.order-completed-view {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #1b1b1b;
  z-index: 16;
}

.oder-completed-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  white-space: pre-line;
  width: 90%;
}

.order-confirm,
.order-details,
.contact-details {
  background-color: #dfdfdf;
  padding: 10px;
}

.order-completed-bottom {
  display: flex;
  justify-content: space-between;
}

.order-completed-btn {
  width: fit-content;
  padding: 10px;
  background-color: #ff4500;
  color: #ffffff;
  border: none;
  outline: inherit;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  align-self: flex-end;
}

.contacts-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.contacts-container a {
  text-decoration: none;
  color: inherit;
}

.maps {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeff2;
  height: 300px;
  background-image: url(../assets/maps.png);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.contacts-container span {
  font-weight: 600;
}

.contact-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form-container input,
textarea {
  background-color: #f5f5f5;
  padding: 10px;
  outline: inherit;
  border: none;
  border-radius: 0px;
  flex-grow: 1;
}

.contact-form-first-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form-first-block div {
  display: flex;
  gap: 10px;
}

.message-textarea {
  min-width: 100%;
  height: 300px;
  resize: none;
}

.contact-form-container p {
  font-size: 12px;
  text-align: end;
  color: #999999;
}

.contact-form-container button {
  border: none;
  outline: inherit;
  align-self: flex-end;
  padding: 15px;
  background-color: #ff4500;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  transition: 200ms;
}

@media (hover: hover) {
  .contact-form-container button:hover {
    cursor: pointer;
  }
}

.contact-form-attachment {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.footer {
  height: 65px;
  background-color: #eaeff2;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px #eaeff2 inset;
}

.footer-rights-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.footer-rights {
  text-decoration: none;
  color: #ff4500;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
}

.footer-container {
  width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.social-container {
  display: flex;
  gap: 15px;
  height: 32px;
}

.social-icon {
  cursor: pointer;
  color: #ff4500;
}

.error-view {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: white;
  color: #1b1b1b;
  z-index: 15;
}

.error-view button,
.thank-you-view button {
  padding: 12px;
  background-color: #1b1b1b;
  color: #f5f5f5;
  border: none;
  cursor: pointer;
  transition: 200ms;
}

.error-view a {
  color: #ffffff;
  text-decoration: none;
}

@media (hover: hover) {
  .error-view button:hover,
  .thank-you-view button:hover {
    background-color: #ff4500;
  }
}

.thank-you-view {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: white;
  color: #1b1b1b;
  z-index: 16;
}

.thank-you-view-content {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  gap: 15px;
}

.thank-you-back {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  transition: 200ms;
}

@media (hover: hover) {
  .thank-you-back:hover {
    color: #ff4500;
  }
}

@media only screen and (max-width: 1100px) {
  .cookie-container {
    height: 150px;
    flex-direction: column;
    padding: 0px;
    gap: 5px;
    justify-content: center;
  }

  .cookie-container p {
    padding: 15px;
    padding-bottom: 5px;
  }

  .cookie-container p,
  .cookie-btns-container button {
    font-size: 12px;
  }

  .cookie-btns-container button {
    padding: 10px;
  }

  .cookie-btns-container {
    align-self: flex-end;
    padding: 15px;
    padding-top: 0px;
  }

  .header-container {
    display: none;
  }

  .description,
  .contact,
  .services,
  .service {
    padding-top: 0;
    padding-bottom: 0;
  }

  .description-container,
  .contact-container,
  .services-container,
  .service-container {
    gap: 15px;
    margin: 10%;
  }

  .service-container {
    max-width: 85%;
  }

  .service-card-container {
    width: 100%;
  }

  .services-cards-container a {
    width: 100%;
  }

  .service-card {
    height: 335px;
    width: 100%;
  }

  .shop-cat-container {
    flex-direction: column;
    gap: 0px;
  }

  .shop-cat-form-container {
    width: 100%;
  }

  .shop-cat-img img {
    width: 100%;
  }

  .shop-cat-left-side {
    height: fit-content;
    gap: 0px;
  }

  .contacts-container {
    flex-direction: column;
  }

  .contact-form-first-block div {
    flex-direction: column;
  }

  .footer {
    height: 200px;
  }

  .footer-container {
    flex-direction: column;
    justify-content: center;
    width: 100vh;
    gap: 8px;
    grid-gap: 8px;
  }

  .footer-rights-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    grid-gap: 8px;
  }

  .social-container {
    margin-bottom: 5px;
  }
}
