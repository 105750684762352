.slider-container {
  max-width: 100%;
  gap: 5px;
  height: 320px;
}

.slider-image {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 200ms;
}

.slider-image img {
  height: 70%;
}

/* .service-title {
  color: #000000;
} */

.service-title-active {
  color: #ff4500;
}

@media (hover: hover) {
  .slider-image:hover,
  .service-title-active:hover {
    color: #ff4500 !important;
  }
}

.arrow {
  padding: 3px;
  background-color: #ffffffcc;
  color: #000000cc;
  border: solid;
  border-width: thin;
  border-color: #000000cc;
  transition: 200ms;
}

@media (hover: hover) {
  .arrow:hover {
    background-color: #ff4500;
  }
}

.prev-arrow {
  cursor: pointer;
  margin-top: -90px;
  margin-right: -31px;
}

.next-arrow {
  cursor: pointer;
  margin-top: -90px;
  margin-left: -30px;
}

.slide {
  text-align: center;
  height: 320px;
}

.react-slideshow-container + ul.indicators {
  margin-top: 5px !important;
}

@media only screen and (min-width: 1100px) {
  .arrow {
    padding: 8px;
    height: 50px;
    width: 50px;
  }

  .prev-arrow {
    cursor: pointer;
    margin-top: -95px;
    margin-right: -55px;
  }

  .next-arrow {
    cursor: pointer;
    margin-top: -95px;
    margin-left: -55px;
  }

  .react-slideshow-container + ul.indicators {
    margin-top: -50px !important;
  }

  .service-desc-container {
    margin-top: -70px;
  }
}

.tshirt-title {
  font-weight: 500;
}

.tshirt-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.colors-container {
  display: flex;
  flex-direction: row;
  gap: 75px;
  grid-gap: 75px;
}

.color-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tshirt-color {
  height: 20px;
  width: 20px;
}

.bbfirstservice {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}

.bbfirstservice-colors {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media only screen and (max-width: 1100px) {
  .slide {
    height: 150px;
  }

  .slide p {
    font-size: 13px;
  }

  .colors-container {
    flex-direction: column;
    gap: 0px;
    grid-gap: 0px;
  }

  .digital-printing-container {
    flex-direction: column;
  }

  .digital-printing-container img {
    width: 100%;
  }

  .service-title {
    font-size: 26px;
  }

  .bbfirstservice {
    flex-direction: column;
    align-items: flex-start;
  }

  .prev-arrow {
    cursor: pointer;
    margin-top: -38px;
    margin-right: -31px;
  }

  .next-arrow {
    cursor: pointer;
    margin-top: -38px;
    margin-left: -31px;
  }
}
